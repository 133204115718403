import { Apollo } from 'apollo-angular';
import { Component, ElementRef, Inject, Input, LOCALE_ID, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, ROUTES } from '@angular/router';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Page, PageResponse } from 'src/app/apollo/models/base-models';
import { STATIC_PAGE_CONTENT_QUERY } from 'src/app/apollo/static-page';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { LoaderService } from 'src/app/services/utils/loader/loader.service';
import { PageMappingService } from 'src/app/services/utils/page-mapping.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { HOST_NAME, LICENCE } from 'src/app/global.tokens';
import { WINDOW } from '@ng-web-apis/common';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { BaseComponent } from '../base/base.component';
import { loadChatScript } from 'src/app/utils/load-chat';
import { isPlatformBrowser } from '@angular/common';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { depositTrx, paymentTrx } from 'src/app/router-translation.labels';
import { SeoSchemaValidator } from 'src/app/services/seo/seo-schema-validator';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent extends BaseComponent {

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    private sanitizer: DomSanitizer,
    private router: Router,
    private mappingService: PageMappingService,
    private toggleBackService: ToggleMenuBackService,
    private loaderService: LoaderService,
    public deviceDetector: DeviceDetectorService,
    public minimizeSiteService: MinimizeSiteService,
    public loginStatusService: LoginStatusService,
    private licenceConfigService: LicenceService,
    protected translationConfig: TranslationConfig,
    protected seoSchemaValidator: SeoSchemaValidator,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) private hostname: any,
    @Inject(LICENCE) public license: string,
    @Inject(PLATFORM_ID) public platformId: string,
    @Inject(ROUTES) private routes,
    @Inject(WINDOW) readonly windowRef: Window,
    private el: ElementRef,
    private renderer: Renderer2
  ) { super() }

  private mapping: Map<string, string>;

  pageJson: Page;
  body: SafeHtml;
  isHtml: boolean = false;
  isNotFound: boolean = false;
  paymentUrl: string[] = [`/${this.translationConfig.getTranslation(paymentTrx)}`, this.translationConfig.getTranslation(depositTrx)];

  @Input() name: string;
  @Input() category: string;
  @Input() standalone: boolean = true;
  @Input() isDeposit: boolean;

  static replaceTemplateTags(content, licenceConfigService) {
    return content.replace("{CasinoName}", licenceConfigService.getKey("nane"))
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.setupTabs();
        this.setupCardFlip();
      }, 1000); // Ensure the content is fully loaded
    }
  }

  ngOnInit(): void {
    this.setSchemaSets()
    this.route.queryParams.subscribe(params => {
      const minimize = params.minimize === 'true';
      if (minimize) {
        this.minimizeSiteService.minimizePage();
      }
    });

    this.toggleBackService.showBack();
    combineLatest([this.route.paramMap, this.mappingService.getMapping()]).pipe(
      takeUntil(this.unsubscribe),
      debounceTime(0),
      // filter(([params, map]) => !!params.get('name')),
      switchMap(([params, map]) => {
        let pageName = this.name;
        // if (params.get('name') !== null) pageName = map.get(params.get('slug') + '/' + params.get('name'));
        pageName = !!pageName ? pageName : params.get('name');
        let categoryName = !!this.category ? this.category : params.get('slug');
        return this.apollo.watchQuery<PageResponse>({
          query: STATIC_PAGE_CONTENT_QUERY,
          variables: {
            pageName: pageName,
            categoryName: categoryName,
            locale: this.locale,
            licenseName: this.license
          }
        }).valueChanges
      })
    ).subscribe((resp) => {
      // console.log(resp.data);

      this.pageJson = resp.data.pages[0];
      if (!this.pageJson) {
        this.isNotFound = true;
        return;
      }

      if (this.pageJson.name === "support") {
        if (isPlatformBrowser(this.platformId)) {
          this.loginStatusService.getIfUserLogged().pipe(takeUntil(this.unsubscribe)).subscribe((status) => {
            let roles = status.fullInfo?.roles?.map((item: string) => item.toLowerCase());
            const hasVip = !!roles.find((item: string) => item.includes('vip')) || !!roles.some((item: string) => ["EX_VIP_ROW", "EX_VIP_PL", "EX_SVIP_ROW", "EX_SVIP_PL"].includes(item.toUpperCase()));

            if (hasVip) {
              loadChatScript(`vip-${this.locale}`, status.username, status.fullInfo.first_name);
            } else {
              loadChatScript(this.locale, status.username, status.fullInfo.first_name);
            }
            console.log("chat enabled 3")
          });
        }
      }

      if (this.pageJson?.pageType === "html") {
        this.isHtml = true;
        this.prepareLandingPage(this.pageJson);
        let pageContent = this.pageJson.htmlContent;
        if (!!this.pageJson.content) {
          pageContent = this.pageJson.htmlContent.replace("{{content}}", this.pageJson.content.html);
        }
        this.pageJson.pageAdditionalContents.forEach((item) => {
          pageContent = pageContent.replace(`{{${item.name}}}`, item.content.html);
          pageContent = pageContent.replace(`{{${item.name}-url}}`, item.url);
        })
        this.body = this.sanitizer.bypassSecurityTrustHtml(StaticPageComponent.replaceTemplateTags(pageContent, this.licenceConfigService))
      }
      else {
        if (this.pageJson?.pageType === "html") {
          this.isHtml = true;
          let pageContent = this.pageJson.htmlContent.replace("{{content}}", this.pageJson.content.html);
          this.pageJson.pageAdditionalContents.forEach((item) => {
            pageContent = pageContent.replace(`{{${item.name}}}`, item.content.html);
          })
          this.body = this.sanitizer.bypassSecurityTrustHtml(StaticPageComponent.replaceTemplateTags(pageContent, this.licenceConfigService))
        }
        else {
          this.body = this.sanitizer.bypassSecurityTrustHtml(StaticPageComponent.replaceTemplateTags(this.pageJson?.content?.html, this.licenceConfigService));
        }



        this.loaderService.hide();
      }
      const title = !!this.pageJson.seo?.metaTitle ? this.pageJson.seo.metaTitle : `${this.pageJson.displayName} | Lemon Casino`;

      const description = !!this.pageJson.seo?.metaDescription
        ? this.pageJson.seo.metaDescription
        : $localize`:@@description-static-page: Read to know more about ${this.pageJson.displayName} at Lemon Casino in "year". Register today to get your welcome bonus, play and win more with Lemon Casino.`;
      this.setSEOTags(title, description, this.pageJson.seo?.relCanonical, null, this.pageJson.seo?.noIndex);
    }, (error) => {
      // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      // this.router.navigate(['/not-found']));
    });
  }

  redirectPayment() {
    if (this.pageJson.bonuses.length > 0) {
      this.router.navigate(this.paymentUrl, { state: { bonus: this.pageJson.bonuses[0]?.externalId } });
    }
    else {
      this.router.navigate(this.paymentUrl);
    }
  }

  prepareLandingPage(page: Page) {
    if (!!page.landingPage) {
      this.minimizeSiteService.minimizePage();
    }
  }

  setupTabs() {
    const tabs = this.el.nativeElement.querySelectorAll(".tablinks");
    const tabContents = this.el.nativeElement.querySelectorAll(".tabcontent");

    tabs.forEach((tab: HTMLElement) => {
      this.renderer.listen(tab, "click", () => {
        const targetId = tab.getAttribute("data-target");
        const targetContent = this.el.nativeElement.querySelector(`#${targetId}`);

        tabs.forEach((otherTab: HTMLElement) => this.renderer.removeClass(otherTab, "active"));
        tabContents.forEach((content: HTMLElement) => this.renderer.setStyle(content, "display", "none"));

        this.renderer.addClass(tab, "active");
        document.querySelectorAll(`.${tab.textContent}`).forEach((el) => {
          (el as HTMLElement).style.display = "block";
        });
        if (targetContent) {
          this.renderer.setStyle(targetContent, "display", "block");
        }
      });
    });
  }

  setupCardFlip() {
    const cards = this.el.nativeElement.querySelectorAll(".card-inner");

    cards.forEach((card: HTMLElement) => {
      this.renderer.listen(card, "click", () => {
        cards.forEach((otherCard: HTMLElement) => {
          if (otherCard !== card) {
            this.renderer.removeClass(otherCard, "is-flipped");
          }
        });
        if (card.classList.contains("is-flipped")) {
          this.renderer.removeClass(card, "is-flipped");
        } else {
          this.renderer.addClass(card, "is-flipped");
        }

      });
    });
  }

  setSchemaSets() {
    const schemaData = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": `${this.hostname}`,
          "url": `${this.hostname}`,
          "name": "Lemon Casino",
          "inLanguage": this.locale,
          "isPartOf": {
            "@type": "WebSite",
            "@id": `${this.hostname}/#webpage`,
            "name": "Lemon Casino",
            "url": this.hostname
          },
        },
        {
          "@type": "Organization",
          "@id": `${this.hostname}/#organization`,
          "name": "Lemon Casino",
          "url": `${this.hostname}`,
          "logo": {
            "@type": "ImageObject",
            "url": `${this.hostname}/assets/logo/desktop-logo-125x32.svg`
          },
          "brand": "Lemon Casino",
          "sameAs": ["https://t.me/lemoncasino"]
        },
        {
          "@type": "WebPage",
          "@id": `${this.hostname}/#webpage`,
          "url": `${this.hostname}`,
          "name": "Lemon Casino",
          "inLanguage": this.locale,
          "about": {
            "@id": `${this.hostname}/#organization`
          }
        }
      ]
    };
    this.seoSchemaValidator.addSchemaMarkup(schemaData)
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
